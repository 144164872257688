import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

// Reducers
import UIReducer from "./reducers/UIReducer";
import NavigationReducer from "./reducers/NavigationReducer";
import UserReducer from "./reducers/UserReducer";
import EditorReducer from "./reducers/EditorReducer";
import LayoutReducer from "./reducers/LayoutReducer";
import ArrayReducer from "./reducers/ArrayReducer";


//
const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  Nav: NavigationReducer,
  User: UserReducer,
  UI: UIReducer,
  Editor: EditorReducer,
  Layout: LayoutReducer,
  Array: ArrayReducer,
});

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
