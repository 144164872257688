import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  documentIds: [], // Initial empty array
};

const arraySlice = createSlice({
  name: 'array',
  initialState,
  reducers: {
    addDocumentIds: (state, action) => {
      state.documentIds.push(action.payload);
    },
    setDocumentIds: (state, action) => {
      state.documentIds = action.payload;
    },
    clearDocumentIds: (state) => {
      state.documentIds = [];
    },
  },
});

export const { addDocumentIds, setDocumentIds, clearDocumentIds } = arraySlice.actions;
export default arraySlice.reducer;