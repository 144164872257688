import logger from "../@global/logger";

export const getSiteId = () => {
  let site_id = document.querySelector('meta[name="site-id"]').content;

  site_id = site_id === "$SITE_ID" ? window._env_.REACT_APP_FALLBACK_SITE_ID : site_id;

  return site_id;
};

export const isDefined = (param) => { // Function to check if a variable/array is empty or not 
  return (param !== null && param !== undefined) ? true : false;
}

export const getPageVerVars = (page_ver_id_lookup, page_id) => {
  let version_id = "";
  let request_active = true;

  if (page_ver_id_lookup[page_id]) {
    version_id = page_ver_id_lookup[page_id];
    request_active = null;
  }

  return { id: version_id, active: request_active };
};


export const isEmptyName = (param) => { // Function to check if a variable/array is empty or not 
  return (param === null || param.length<1) ? true : false;
}

export const isEmptyArray = (param) => {
  if (!isDefined(param)) {
    return true
  }
  
  if (Array.isArray(param)) {
    return param.length < 1
  }
  
  if (typeof param === 'object' && param !== null) {
    return Object.keys(param).length === 0
  }
  
  return false
};

/**
 * Get distinct valuse from an object of object 
 *
 * @property {array} array
 */
 export const distinctArray = (array) => {
  // Remove the duplicates tags
  let set = new Set();
  let distinctAssignedGroupTags = array.filter(tag => {
      if (!set.has(tag.tag)) {
          set.add(tag.tag);
          return true;
      }
      return false;
  }, set);
  return distinctAssignedGroupTags;
}


export const validatePageName = (str) => {
  const regex = /^[a-z0-9 _]+$/i;
  return str.match(regex);
};

export const validatePageURI = (str) => {
  const regex = /^[a-z0-9\-_]+$/i;
  return str.match(regex);
};

export const getPointBoxCollides = (point, box) => {
  return (
    point.x >= box.x &&
    point.x <= box.x + box.width &&
    point.y >= box.y &&
    point.y <= box.y + box.height
  );
};

export const getPaletteColor = (path = "", theme) => {
  const steps = path.split(".");

  let obj_ref = theme.palette;

  steps.forEach((step) => {
    if (obj_ref) obj_ref = obj_ref[step];
  });

  return obj_ref;
};

export const getLinkType = (url) => {
  try {
    const parsedUrl = new URL(url, document.location.origin); // Ensure valid URL, relative to the current origin if needed
    const is_relative = !parsedUrl.host || parsedUrl.origin === document.location.origin;
    const internal = is_relative || parsedUrl.host === document.location.host;

    return { 
      internal, 
      url: internal ? parsedUrl.pathname + parsedUrl.search + parsedUrl.hash : url 
    };
  } catch (e) {
    // If the URL cannot be parsed (e.g., it's malformed), treat it as relative
    return { internal: true, url };
  }
};

export const getAnchorHash = (hash = "") => {
  if (hash.length > 0) {
    const amp_pos = hash.indexOf("&");
    const end_char = amp_pos === -1 ? hash.length : amp_pos;

    return hash.substring(0, end_char);
  }
  return hash;
};

export const getURLvars = (search = "") => {
  const vars = {};

  if (search.length > 0 && search.indexOf("?") > -1) {
    const entries = search.substring(1, search.length).split("&");

    entries.forEach((entry) => {
      const duple = entry.split("=");

      vars[duple[0]] = duple[1];
    });
  }

  return vars;
};

export const copyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    logger.debug("Fallback: Copying text command was " + msg);
  } catch (err) {
    logger.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};
